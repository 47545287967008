<template>
  <div class="student-fee-details">
    <h1 class="mx-4 text-left">Fee Details</h1>
    <h5 class="text-left mx-4 mb-2">{{ activeFeeSession.title }}</h5>
    <v-row>
      <!-- Details, due and balance-->
      <v-col cols="12" md="8">
        <v-card class="px-4 py-2 mx-4" v-if="profile">
          <h3 class="text-left">{{ profile.user.full_name || "-" }}</h3>
          <v-row>
            <v-col cols="6" md="3">
              <p class="text-left">
                <small>Date of Admission</small>
              </p>
              <p class="text-left">
                <small>Student Category</small>
              </p>
              <p class="text-left">
                <small>Classroom</small>
              </p>
              <p class="text-left">
                <small>Roll Number</small>
              </p>
            </v-col>
            <v-col cols="6" md="3">
              <p class="font-weight-bold text-left">
                <small>{{ profile.date_of_admission || "-" }}</small>
              </p>
              <p :class="accountDetails.category.code ? 'font-weight-bold text-left' :'red--text font-weight-bold text-left' ">
                <small>{{ accountDetails.category.code ? accountDetails.category.code : "Not Assigned" }}</small>
              </p>
              <p class="font-weight-bold text-left">
                <small>{{
                  accountDetails.room ? accountDetails.room.sections : "Not Assigned"
                }}</small>
                <!-- <small>{{ profile.user.room.sections || "Not Assigned" }}</small> -->
              </p>
              <p class="font-weight-bold text-left">
                <small>{{ profile.user.roll_number || "-" }}</small>
              </p>
            </v-col>
            <v-col cols="12" class="hidden-sm-and-down text-right" md="6">
              <p class="text--secondary">{{ profile.user.phone || "-" }}</p>
              <p class="text--secondary">{{ profile.parent_email || "-" }}</p>
              <p class="text--secondary">{{ profile.address || "-" }}</p>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="6" md="3">
              <p class="text-left">
                <small>Total Fees</small>
              </p>
              <p class="text-left">
                <small>Total Balance</small>
              </p>
              <p class="text-left">
                <small>Total Paid</small>
              </p>
              <p class="text-left">
                <small>Total Fine</small>
              </p>
              <p class="text-left mb-0">
                <small>Total Concession</small>
              </p>
            </v-col>
            <v-col cols="6" md="4">
              <p class="font-weight-bold text-right">
                {{ currency_format(accountDetails.total_fees || 0) }}
              </p>
              <p class="font-weight-bold text-right">
                {{ currency_format(accountDetails.total_balance || 0) }}
              </p>
              <p class="font-weight-bold text-right">
                {{ currency_format(accountDetails.total_paid || 0) }}
              </p>

              <p class="font-weight-bold text-right">
                {{ currency_format(accountDetails.total_fine || 0) }}
              </p>
              <p class="font-weight-bold text-right mb-0">
                {{ currency_format(accountDetails.total_concession || 0) }}
              </p>
            </v-col>
            <v-col cols="12" md="5">
              <v-divider class="mx-4 hidden-md-and-up"></v-divider>
              <v-row class="hidden-md-and-up" v-if="next_payment_details">
                <v-col cols="6" md="12" class>
                  <p class="text-left mb-0 text--secondary">Amount Due</p>
                </v-col>
                <v-col cols="6" md="12" class>
                  <h3 class="text-right">
                    {{ currency_format(next_payment_details.amount_due || 0) }}
                  </h3>
                </v-col>
                <v-col cols="6" md="12" class="py-0">
                  <p class="text-left mb-0 text--secondary">
                    Next Payment Due on
                  </p>
                </v-col>
                <v-col cols="6" md="12" class="py-0">
                  <h3
                    :class="[
                      next_payment_details.is_overdue
                        ? 'red--text text-right'
                        : 'text-right',
                    ]"
                  >
                    {{ next_payment_details.next_payment || "-" }}
                  </h3>
                </v-col>
              </v-row>
              <div class="hidden-sm-and-down" v-if="next_payment_details">
                <p class="text-right mb-0 text--secondary">Amount Due</p>
                <h3 class="text-right">
                  {{ currency_format(next_payment_details.amount_due || 0) }}
                </h3>
                <p class="text-right mb-0 text--secondary">
                  Next payment Due on
                </p>
                <h3
                  :class="[
                    next_payment_details.is_overdue
                      ? 'red--text text-right'
                      : 'text-right',
                  ]"
                >
                  {{ next_payment_details.next_payment || "-" }}
                </h3>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <!-- Payment Plan -->
      <v-col cols="12" md="4">
        <v-card
          v-if="
            accountDetails.category.allow_pending_collection &&
            accountDetails.overdue_accounts &&
            accountDetails.overdue_accounts.length
          "
          class="mx-4 mb-4"
        >
          <v-card-title class="pb-0 red--text text-left"
            >Pending fees from other academic year(s)</v-card-title
          >

          <v-list-item
            v-for="account in accountDetails.overdue_accounts"
            :key="account.id"
          >
            <v-list-item-content>
              <v-list-item-title>
                <b
                  ><a @click="changeActiveFeeSession(account.session)">{{
                    account.session.title
                  }}</a></b
                >
              </v-list-item-title>
              <!-- <v-list-item-subtitle>
                <b
                  :class="{'green-color': instalment.is_paid}"
                >{{ currency_format(instalment.total_fees) }}</b>
                <br />
                <small :class="{'green-color': instalment.is_paid}">
                  <b>{{ moment(instalment.start_date).format('D MMM YYYY') }} - {{ moment(instalment.end_date).format('D MMM YYYY') }}</b>
                </small>
              </v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card class="mx-4 mb-4">
          <v-card-title class="pb-0"
            >Payment Plan ({{ this.activeFeeSession.title }})</v-card-title
          >
          <p class="text-left mx-4 my-0">
            {{ payment_plan.length }} Instalments
          </p>
          <v-list-item v-for="instalment in payment_plan.slice(0, showAllInstalments ? payment_plan.length : 3 )" :key="instalment.id">
            <v-list-item-content>
              <v-list-item-title :class="{ 'green-color': instalment.is_paid }">
                <small>
                  <b>{{ instalment.title }}</b>
                </small>
              </v-list-item-title>
              <v-list-item-subtitle>
                <b :class="{ 'green-color': instalment.is_paid }">{{
                  currency_format(instalment.total_fees)
                }}</b>
                <br />
                <small :class="{ 'green-color': instalment.is_paid }">
                  <b
                    >{{ moment(instalment.start_date).format("D MMM YYYY") }} -
                    {{ moment(instalment.end_date).format("D MMM YYYY") }}</b
                  >
                </small>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action v-if="instalment.is_paid">
              <v-icon :class="{ 'green-color': instalment.is_paid }"
                >mdi-check</v-icon
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="payment_plan.length>3">
            <v-btn block color="primary" @click="showAllInstalments = !showAllInstalments" small>{{ showAllInstalments ? 'Hide All Instalments' : 'Show All Instalments' }}</v-btn>
          </v-list-item>
        </v-card>
      </v-col>
      <!-- History -->
      <v-col cols="12" md="8">
        <v-card
          class="mx-4 my-4"
        >
          <v-card-title>
            <v-row>
              <v-col cols="12" md="8">
                <p class="mb-0 text-left">
                  Payment History
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <v-switch
                  class="my-0"
                  v-model="showAllHistory"
                  label="Show all payments"
                  @change="getStudentPaymentHistory"
                ></v-switch>
              </v-col>
            </v-row>

          </v-card-title>
          <v-data-table
            :headers="paymentHeaders"
            sort-by="created"
            :sort-desc="true"
            :items="payments"
            :items-per-page="15"
            hide-default-footer
            @click:row="openFeeReceipt"
            class="row-pointer"
          >
            <template slot="no-data">
              <i class="fa fa-receipt"></i>
              No Transactions found.
            </template>
            <template v-slot:item.receipt_number="{ item }">
              <span class="text-decoration-underline blue--text">{{
                item.receipt_number
              }}</span>
            </template>
            <template v-slot:item.created="{ item }">
              <span>{{
                moment(item.created).format("D MMM YYYY hh:mma")
              }}</span>
            </template>
            <template v-slot:item.mode="{ item }">
              <span>{{ item.mode }}</span>
            </template>
            <template v-slot:item.total_amount_paid="{ item }">
              <span
                :class="[
                  item.mode == 'REFUND'
                    ? 'red--text font-weight-bold'
                    : 'green--text font-weight-bold',
                ]"
                >{{ currency_format(item.total_amount_paid) }}</span
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Analytics -->
      <v-col
        cols="12"
        md="4"
        v-if="payment_plan.length"
      >
        <h2>Student Fee Analytics</h2>
        <FeeAnalytics
          v-if="accountDetails"
          :series="[
            accountDetails.total_paid || 0,
            accountDetails.total_balance || 0,
          ]"
          :labels="['Paid', 'Due']"
          :colors="['#4CD137', '#FF7700']"
        />
      </v-col>
      <v-col cols="12">
        <div v-if="accountDetails.category.allow_pending_collection && accountDetails.overdue_accounts && accountDetails.overdue_accounts.length" class="mx-6 mb-4">
          <v-btn v-for="account in accountDetails.overdue_accounts" :key="account.id" block color="red" outlined @click="changeActiveFeeSession(account.session)">
            Collect Fees ({{ account.session.title }}) 
          </v-btn>
           
        </div>
        <PayFeeCard
          @nextPaymentDetails="(details) => (next_payment_details = details)"
          :profile="profile"
          :category="accountDetails.category"
          :sid="currentUser.id"
          v-if="feePortalPreference && !feePortalPreference.disable_online_payments"
          :collectFeeButtonDisabled="((feePortalPreference.disable_online_payments) || (accountDetails.category.restrict_collection_if_pending && (accountDetails.overdue_accounts && accountDetails.overdue_accounts.length > 0)))"
        />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.visible">
      {{ snackbar.text }}
      <template>
        <v-btn color="pink" text @click="snackbar.visible = false">Close</v-btn>
      </template>
    </v-snackbar>
    <ConfirmationDialog
      v-if="sessionChangeConfirmationDialogCallback != null"
      :visible="showSessionChangeConfirmationDialog"
      @successCallback="sessionChangeConfirmationDialogCallback"
      @failureCallback="showSessionChangeConfirmationDialog = false"
      title="Are you sure you want to switch the current academic year?"
      successText="Proceed"
      failureText="Cancel"
    />
  </div>
</template>

<script>
import FeeAnalytics from "@components/fees/FeeAnalytics.vue";
import { mapGetters, mapActions } from "vuex";
import api from "@api/index";
import endpoints from "@api/repository";
import moment from "moment";
import PayFeeCard from "@components/fees/PayFeeCard";
import Helper from "@utils/misc";
import ConfirmationDialog from "@components/widgets/dialogs/ConfirmationDialog.vue";

export default {
  name: "StudentFeeDetails",
  components: {
    FeeAnalytics,
    PayFeeCard,
    ConfirmationDialog,
  },
  data() {
    return {
      moment: moment,
      paymentHeaders: [
        { text: "Receipt Number", value: "receipt_number" },
        { text: "Academic Year", value: "session.title" },
        { text: "Transaction Date", value: "created" },
        { text: "Payment Mode", value: "mode" },
        { text: "Amount (INR)", value: "total_amount_paid" },
      ],
      showAllHistory: false,
      showAllInstalments: false,
      profile: null,
      next_payment_details: null,
      accountDetails: {
        category:{
          allow_pending_collection: false,
          restrict_collection_if_pending: false,
        }
      },
      feePortalPreference: null,
      payments: [],
      snackbar: {
        visible: false,
        text: "",
      },
      payment_plan: [],
      showSessionChangeConfirmationDialog: false,
      sessionChangeConfirmationDialogCallback: null,
    };
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "accessToken",
      "isLoading",
      "activeFeeSession",
      "feeSessionData",
      "currentInstitution"
    ]),
    essentials() {
      return {
        accessToken: this.accessToken,
        setLoading: this.setLoading,
        handleErrorsFunction: this.handleApiErrors,
      };
    },
  },
  methods: {
    ...mapActions(["setLoading", "setActiveSessionData"]),
    currency_format(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "INR",
      });
      return formatter.format(amount);
    },

    async changeActiveFeeSession(session) {
      if (!this.currentUser.privileges.canChangeFeeSessions) {
        this.snackbar.text = "You do not have the sufficient permissions";
        this.snackbar.visible = true;
        return;
      }

      this.sessionChangeConfirmationDialogCallback = null;
      this.showSessionChangeConfirmationDialog = true;
      this.sessionChangeConfirmationDialogCallback = () => {
        this.setActiveSessionData(session);
        window.location.reload();
      };
    },

    //get fee portal preference
    async getFeePortalPreference(){
      var url = endpoints.getFeePortalPreference + `${this.currentInstitution.id}/`
      this.feePortalPreference = await api.call(this.essentials, url)
    },

    // gets the student profile details from the server
    async getStudentProfileDetails() {
      var response;
      var url = Helper.addUrlParams(
        endpoints.getFeeStudentProfileDetails + this.currentUser.id + "/",
        [`session=${this.activeFeeSession.id}`]
      );
      response = await api.handleApi({
        url: url,
        apiEssentials: this.essentials,
        responseVar: response,
      });

      if (response) {
        this.profile = response;
      }
    },

    // gets student's payment plan
    async getStudentPaymentPlan() {
      var url = Helper.addUrlParams(
        endpoints.getFeeStudentPaymentPlan + this.currentUser.id + "/",
        [`session=${this.activeFeeSession.id}`]
      );
      this.payment_plan = (await api.call(this.essentials, url)) ?? [];
    },

    // gets student's payment plan
    async getStudentPaymentHistory() {
      var url = Helper.addUrlParams(
        endpoints.getFeeStudentPaymentHistory + this.currentUser.id + "/",
        [`session=${this.activeFeeSession.id}`,`showAll=${this.showAllHistory}`]
      );
      var response = (await api.call(this.essentials, url)) ?? {};

      this.payments = response.payment_receipts.concat(
        response.refund_receipts
      );

      this.payments.sort((a, b) =>
        moment(a.created).isAfter(moment(b.created)) ? -1 : 1
      );
    },

    // gets student's fee account details
    async getStudentFeeAccountDetails() {
      var url = Helper.addUrlParams(
        endpoints.getFeeStudentAccountDetails + this.currentUser.id + "/",
        [`session=${this.activeFeeSession.id}`]
      );
      this.accountDetails = (await api.call(this.essentials, url)) ?? null;
    },

    // Go to Fee Receipt
    openFeeReceipt(receipt) {
      if (receipt.mode == "REFUND") {
        this.$router.push({
          name: "ViewRefund",
          params: { sid: this.$route.params.sid, rid: receipt.id },
        });
      } else {
        this.$router.push({
          name: "ViewFeesPaid",
          params: { sid: this.$route.params.sid, rid: receipt.id },
        });
      }
    },

    async handleApiErrors(err) {
      this.setLoading(false);
      if (err.response) {
        console.log(err.response);
      } else {
        this.snackbar.text = "Unknown error occurred";
        this.snackbar.visible = true;
      }
    },
  },
  created() {
    this.getStudentProfileDetails();
    this.getStudentFeeAccountDetails();
    this.getStudentPaymentPlan();
    // this.getStudentFeeAccountDetails();
    this.getStudentPaymentHistory();
    this.getFeePortalPreference();
  },
};
</script>

<style scoped>
.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.rounded-input {
  border-radius: 12px !important;
}
.green-color {
  color: green;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
