<template>
  <div class="collect-fee-card">
    <v-row v-if="!showCollectFeeCard" class="mr-2 ml-2">
      <v-col cols="12">
        <v-tooltip :disabled="scheduleInstalments.length > 0" top>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                v-on="on"
                :disabled="!scheduleInstalments.length || collectFeeButtonDisabled"
                @click="showCollectFeeCard = true"
                block
                color="primary"
                append-icon
              >Pay Fees</v-btn>
            </div>
          </template>
          <span>No Fee Structure Defined</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row v-else>
      <!-- <v-col cols="12" sm="6" md="4">
        <div v-if="scheduleInstalments.length" class="mx-4">
          <v-menu
            v-if="(totalSum(scheduleInstalments[activeTab || 0].instalments, 'paying') > 0)"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="paymentDate"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Select Receipt Date"
                :value="formattedDate(paymentDate)"
                readonly
                style="min-width: 100%"
                outlined
                class="rounded-input"
                dense
                v-bind="attrs"
                v-on="on"
                min-width="290px"
              ></v-text-field>
            </template>
            <v-date-picker v-model="paymentDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(paymentDate)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-col> -->
      <v-col cols="12" sm="6" md="8">
        <div class="mr-8">
        <v-select
          v-model="selectedInstalments"
          :items="scheduleInstalments"
          item-text="title"
          label="Select Instalments"
          style="min-width: 100%"
          multiple
          outlined
          class="rounded-input mx-4"
          dense
          return-object
        >
          <template v-slot:prepend-item>
            <v-list-item
              ripple
              @click="toggleInstalments"
            >
              <v-list-item-action>
                <v-icon :color="selectedInstalments.length > 0 ? 'primary' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>

        </v-select>
        </div>
      </v-col>
      <v-col cols="12">
        <div v-if="scheduleInstalments.length && !selectedInstalments.length">
          <v-card class="mx-4">
            <v-tabs color="primary" v-model="activeTab">
              <v-tab
                v-for="schedule in scheduleInstalments"
                :key="schedule.id"
              >{{schedule.title}} - {{ moment(schedule.end_date).format('MMM YYYY') }}</v-tab>
              <v-tab-item v-for="schedule in scheduleInstalments" :key="schedule.id">
                <v-form
                  :ref="`collectFeeForm-${schedule.id}`"
                  v-model="collectFeeForm"
                  v-if="schedule.instalments.length && totalSum(schedule.instalments, 'stored_balance') > 0.0"
                >
                  <v-data-table
                    :headers="feeHeaders"
                    :items="schedule.instalments"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template
                      v-slot:item.end_date
                    >{{ moment(schedule.end_date).format("DD MMM, YYYY") }}</template>
<!-- 
                    <template v-slot:item.fine="{ item }">
                      <v-text-field
                        color="primary"
                        min="0"
                        type="number"
                        :rules="[rules.no_negative]"
                        :disabled="item.balance<=0"
                        @input="fineOrConcessionChanged(item)"
                        v-model="item.fine"
                        prepend-icon="mdi-currency-inr"
                        width="300px"
                      ></v-text-field>
                    </template>

                    <template v-slot:item.concession="{ item }">
                      <v-text-field
                        color="primary"
                        min="0"
                        @input="fineOrConcessionChanged(item)"
                        :rules="[rules.no_negative, (v) => (v<= item.stored_balance) || 'Amount greater than balance ',]"
                        prepend-icon="mdi-currency-inr"
                        :disabled="item.stored_balance<=0"
                        type="number"
                        v-model="item.concession"
                      ></v-text-field>
                    </template> -->

                    <template v-slot:item.amount="{ item }">{{ Helper.currencyFormat(item.amount)}}</template>
                    <template v-slot:item.balance="{ item }">{{ Helper.currencyFormat(item.balance)}}</template>

                    <template v-slot:item.paying="{ item }">
                      <div class="text-center">
                        <v-text-field 
                          color="primary"
                          prepend-icon="mdi-currency-inr"
                          :rules="[rules.no_negative, rules.required, (value) => value<=item.balance || 'Amount greater than due', (value) => value >= (item.fine - item.concession) || 'Amount less than fine' ]"
                          type="number"
                          min="0"
                          :max="item.balance"
                          v-model="item.paying"
                          v-if="item.balance>0"
                        ></v-text-field>
                        <v-icon v-else color="primary">mdi-check</v-icon>
                      </div>
                      
                    </template>

                    <template slot="body.append">
                      <tr class="hidden-sm-and-down">
                        <!-- <th></th> -->
                        <th>Total</th>
                        <th></th>
                        <th></th>
                        <!-- amount total -->
                        <th
                          class="text-left"
                        >{{ Helper.currencyFormat(totalSum(schedule.instalments, 'amount') || 0) }}</th>
                        <!-- balance total -->
                        <th
                          class="text-left"
                        >{{ Helper.currencyFormat(totalSum(schedule.instalments, 'balance') || 0) }}</th>
                        <!-- paying total -->
                        <th
                          class="text-left"
                        >
                          <span class="ml-6">
                            {{ Helper.currencyFormat(totalSum(schedule.instalments, 'paying') || 0) }}
                          </span>
                        </th>
                      </tr>
                      <tr class="hidden-sm-and-up">
                        <v-row class="px-4">
                          <v-col class="text-left">
                            <b>Total Amount</b>
                          </v-col>
                          <v-col class="text-left">
                            <b>{{ Helper.currencyFormat(totalSum(schedule.instalments, 'amount') || 0) }}</b>
                          </v-col>
                        </v-row>
                        <v-row class="px-4">
                          <v-col class="text-left">
                            <b>Total Balance</b>
                          </v-col>
                          <v-col class="text-right">
                            <b>{{ Helper.currencyFormat(totalSum(schedule.instalments, 'balance') || 0) }}</b>
                          </v-col>
                        </v-row>
                        <v-row class="px-4">
                          <v-col class="text-left">
                            <b>Total Paying</b>
                          </v-col>
                          <v-col class="text-right">
                            <b>{{ Helper.currencyFormat(totalSum(schedule.instalments, 'paying') || 0) }}</b>
                          </v-col>
                        </v-row>
                      </tr>
                    </template>
                  </v-data-table>
                </v-form>
                <div v-else>
                  <v-icon size="50" color="green">mdi-check</v-icon>
                  <h3 class="theme--light green--text">All Fees Paid</h3>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </div>
        <div v-if="selectedInstalments.length">
          <v-card class="mx-4">
                <v-form
                  ref="customCollectFeeForm"
                  v-model="customCollectFeeForm"
                  v-if="customInstalments.length && totalSum(customInstalments, 'stored_balance') > 0.0"
                >
                  <v-data-table
                    :headers="feeHeaders"
                    :items="customInstalments"
                    :items-per-page="-1"
                    hide-default-footer
                  >
                    <template
                      v-slot:item.end_date="{ item }"
                    >{{ moment(item.schedule.end_date).format("DD MMM, YYYY") }}</template>

                    <template v-slot:item.fine="{ item }">
                      <v-text-field
                        color="primary"
                        min="0"
                        type="number"
                        :rules="[rules.no_negative]"
                        :disabled="item.balance<=0"
                        @input="fineOrConcessionChanged(item)"
                        v-model="item.fine"
                        prepend-icon="mdi-currency-inr"
                        width="300px"
                      ></v-text-field>
                    </template>

                    <template v-slot:item.concession="{ item }">
                      <v-text-field
                        color="primary"
                        min=0
                        :max="item.balance"
                        @input="fineOrConcessionChanged(item)"
                        :rules="[rules.no_negative,(v) => (v<= item.stored_balance) || 'Amount greater than balance ', ]"
                        prepend-icon="mdi-currency-inr"
                        :disabled="item.stored_balance<=0"
                        type="number"
                        v-model="item.concession"
                      ></v-text-field>
                    </template>

                    <template v-slot:item.amount="{ item }">{{ Helper.currencyFormat(item.amount)}}</template>
                    <template v-slot:item.balance="{ item }">{{ Helper.currencyFormat(item.balance)}}</template>

                    <template v-slot:item.paying="{ item }">
                      <v-text-field
                        color="primary"
                        prepend-icon="mdi-currency-inr"
                        :rules="[rules.no_negative, rules.required, (value) => value<=item.balance || 'Amount greater than due', (value) => value >= (item.fine - item.concession) || 'Amount less than fine' ]"
                        type="number"
                        min="0"
                        :max="item.balance"
                        v-model="item.paying"
                        v-if="item.balance>0"
                      ></v-text-field>
                      <v-icon v-else color="primary">mdi-check</v-icon>
                    </template>

                    <template slot="body.append">
                      <tr class="hidden-sm-and-down">
                        <!-- <th></th> -->
                        <th>Total</th>
                        <th></th>
                        <th></th>
                        <!-- amount total -->
                        <th
                          class="text-left"
                        >{{ Helper.currencyFormat(totalSum(customInstalments, 'amount') || 0) }}</th>
                        <!-- balance total -->
                        <th
                          class="text-left"
                        >{{ Helper.currencyFormat(totalSum(customInstalments, 'balance') || 0) }}</th>
                        <!-- paying total -->
                        <th
                          class="text-left"
                        >
                          <span class="ml-6">
                            {{ Helper.currencyFormat(totalSum(customInstalments, 'paying') || 0) }}
                          </span>
                        </th>
                      </tr>
                      <tr class="hidden-sm-and-up">
                        <v-row class="px-4">
                          <v-col class="text-left">
                            <b>Total Amount</b>
                          </v-col>
                          <v-col class="text-right">
                            <b>{{ Helper.currencyFormat(totalSum(customInstalments, 'amount') || 0) }}</b>
                          </v-col>
                        </v-row>
                        <v-row class="px-4">
                          <v-col class="text-left">
                            <b>Total Balance</b>
                          </v-col>
                          <v-col class="text-right">
                            <b>{{ Helper.currencyFormat(totalSum(customInstalments, 'balance') || 0) }}</b>
                          </v-col>
                        </v-row>
                        <v-row class="px-4">
                          <v-col class="text-left">
                            <b>Total Paying</b>
                          </v-col>
                          <v-col class="text-right">
                            <b>{{ Helper.currencyFormat(totalSum(customInstalments, 'paying') || 0) }}</b>
                          </v-col>
                        </v-row>
                      </tr>
                    </template>
                  </v-data-table>
                </v-form>
                <div v-else>
                  <v-icon size="50" color="green">mdi-check</v-icon>
                  <h3 class="theme--light green--text">All Fees Paid</h3>
                </div>
          </v-card>
        </div>
      </v-col>
      <v-col
        cols="12"
      >
        <v-btn
          class="mt-3"
          color="primary"
          :disabled="!collectFeeForm || !(totalSum(scheduleInstalments[activeTab || 0].instalments, 'paying') > 0)"
          @click="customInstalments.length > 0 ? sendCustomFeeCollectionData() : sendFeeCollectionData()"
        >Pay now</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import api from "@api/index";
import endpoints from "@api/repository";
import { mapGetters, mapActions } from "vuex";
import Helper from "@utils/misc";

export default {
  name: "CollectFeeCard",
  props: ["sid", "profile","collectFeeButtonDisabled","category"],
  computed: {
    ...mapGetters(["currentUser", "accessToken", "isLoading","activeFeeSession"]),

    essentials() {
      return {
        accessToken: this.accessToken,
        setLoading: this.setLoading,
        handleErrorsFunction: (err) => {
          console.log(err);
        },
      };
    },
    selectedAllInstalments () {
      return this.selectedInstalments.length === this.scheduleInstalments.length
    },
    selectedSomeInstalments () {
      return this.selectedInstalments.length > 0 && !this.selectedAllInstalments
    },
    icon () {
      if (this.selectedAllInstalments) return 'mdi-close-box'
      if (this.selectedSomeInstalments) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

    customInstalments(){
      var payingInstalments = [];
      this.selectedInstalments.map(schedule => { payingInstalments.push(...schedule.instalments) } );
      return payingInstalments;
    }
  },
  data() {
    return {
      menu: false,
      moment: moment,
      Helper: Helper,
      activeTab: 0,
      showCollectFeeCard: false,
      collectFeeForm: true,
      customCollectFeeForm: true,
      paymentDate: new Date().toJSON(),
      rules: {
        no_negative: (value) => value >= 0 || "Invalid",
        required: (value) => !!value || "Required",
      },
      selectedInstalments: [],
      feeHeaders: [
        { text: "Particular", value: "particular.code", sortable: false },
        { text: "Instalment", value: "particular.title", sortable: false },
        { text: "Due Date", value: "end_date", sortable: false },
        { text: "Amount", align: "left", value: "amount", sortable: false },
        { text: "To Pay", value: "balance", sortable: false, align: "left" },
        {
          text: "Paying Now",
          align: "left",
          value: "paying",
          sortable: false,
        },
      ],
      scheduleInstalments: [],
      validScheduleInstalments: [],
      snackbar: {
        visible: false,
        text: "",
      },
    };
  },
  methods: {
    ...mapActions(["setLoading", "setFeeCollectionData"]),
    totalSum(items, prop) {
      return Helper.totalSum(items, prop);
    },
    toggleInstalments () {
        this.$nextTick(() => {
          if (this.selectedAllInstalments) {
            this.selectedInstalments = []
          } else {
            this.selectedInstalments = this.scheduleInstalments.slice()
          }
        })
      },

    fineOrConcessionChanged(item) {
      // add concession to balance
      item.balance =
        parseInt(item.stored_balance || 0) -
        parseInt(item.concession || 0) +
        parseInt(item.fine || 0);
      item.paying = parseInt(item.balance || 0);
    },

    validateFields() {
      if (moment(this.paymentDate).isAfter(moment(new Date()))) {
        this.snackbar.text = "Payment Date cannot be in the future";
        this.snackbar.visible = true;
        return false;
      }
      return true;
    },

    async sendCustomFeeCollectionData(){
      if (!this.validateFields()) return;
      var totals = {
        amount: this.totalSum(
          this.customInstalments,
          "amount"
        ),
        fine: this.totalSum(
          this.customInstalments,
          "fine"
        ),
        concession: this.totalSum(
          this.customInstalments,
          "concession"
        ),
        balance: this.totalSum(
          this.customInstalments,
          "balance"
        ),
        paying: this.totalSum(
          this.customInstalments,
          "paying"
        ),
      };
      var feeCollectionData = {
        student: this.sid, // student id
        profile: this.profile,
        category : this.category,
        instalments : this.customInstalments,
        totals: totals,
        payment_date: this.paymentDate,
        is_custom: true,
      };
      this.setFeeCollectionData(feeCollectionData);
      // navigate to CollectFeePage
      this.$router.push({ name: "PayFee" });

    },

    async sendFeeCollectionData() {
      if (!this.validateFields()) return;
      var activeTabId =
        "collectFeeForm-" + this.scheduleInstalments[this.activeTab].id;
      console.log("activeTabID :", activeTabId);
      if (!this.$refs[activeTabId][0].validate()) return;
      var totals = {
        amount: this.totalSum(
          this.scheduleInstalments[this.activeTab].instalments,
          "amount"
        ),
        fine: this.totalSum(
          this.scheduleInstalments[this.activeTab].instalments,
          "fine"
        ),
        concession: this.totalSum(
          this.scheduleInstalments[this.activeTab].instalments,
          "concession"
        ),
        balance: this.totalSum(
          this.scheduleInstalments[this.activeTab].instalments,
          "balance"
        ),
        paying: this.totalSum(
          this.scheduleInstalments[this.activeTab].instalments,
          "paying"
        ),
      };
      // get necessary fields
      var feeCollectionData = {
        student: this.sid, // student id
        profile: this.profile,
        category : this.category,
        schedule_instalment: this.scheduleInstalments[this.activeTab],
        totals: totals,
        payment_date: this.paymentDate,
      };
      // save fee collection data to store
      this.setFeeCollectionData(feeCollectionData);
      // navigate to CollectFeePage
      this.$router.push({ name: "PayFee" });
    },
    // gets fee collection data for displaying
    async getDataForFeeCollection() {
      var url = Helper.addUrlParams(endpoints.getStudentDataForFeeCollection + this.sid + "/",[`session=${this.activeFeeSession.id}`]);
      this.scheduleInstalments = await api.call(this.essentials, url);
      this.scheduleInstalments.map((si) => {
        // initalise variables needed for counting and transactions
        si.instalments.map((i) => {
          i.paying = i.balance;
          i.stored_balance = i.balance;
        });
        // remove schedule instalment if the total balance of a schedule is 0
        // if(this.totalSum(si.instalments, "balance") !== 0.0){
        //   this.validScheduleInstalments.push(si);
        // }
      });
      // if current tab total paid, move to next tab
      this.openAppropriateTab();
    },

    async openAppropriateTab() {
      if(!this.scheduleInstalments.length) return;
      while (
        this.activeTab != this.scheduleInstalments.length - 1 &&
        Helper.totalSum(this.scheduleInstalments[this.activeTab].instalments, 'stored_balance') == 0.0
      ) {
        this.activeTab++;
      }
      this.emitNextPaymentDetails();
    },

    formattedDate(date) {
      if (!date) return "";
      return moment(date).format("D MMM YYYY");
    },

    async emitNextPaymentDetails() {
      if (!this.scheduleInstalments.length) return;
      var lastSchedulePaid = false;
      if (
        this.activeTab == this.scheduleInstalments.length - 1 &&
        Helper.totalSum(this.scheduleInstalments[this.activeTab].instalments, 'stored_balance') == 0.0
      ) {
        lastSchedulePaid = true;
      }
      this.$emit("nextPaymentDetails", {
        next_payment: lastSchedulePaid
          ? "-"
          : moment(this.scheduleInstalments[this.activeTab].end_date).format(
              "D MMM YYYY"
            ),
        amount_due: this.totalSum(
          this.scheduleInstalments[this.activeTab].instalments,
          "stored_balance"
        ),
        is_overdue: lastSchedulePaid ? false : moment(this.scheduleInstalments[this.activeTab].end_date).isBefore(moment(new Date())),
      });
    },
  },
  created() {
    this.getDataForFeeCollection();
  },
};
</script>
<style lang="css" scoped>
.green-color {
  color: green;
}
.v-text-field {
  width: 120px;
}
.rounded-input {
  border-radius: 12px !important;
}
</style>