<template>
  <div class="fee-analytics d-flex justify-center">
    <apexchart type="pie" :width="width" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "FeeAnalytics",
  components: {
    apexchart: VueApexCharts,
  },
  props: ["series", "labels", "colors", "width"],
  data() {
    return {
      chartOptions: {
        fill: {
          type: "solid",
          shade: "light",
        },
        chart: {
          id: "FeeAnalytics"
        },
        labels: this.labels,
        colors: this.colors,
        legend: {
          position: 'bottom',
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 360,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    };
  },
};
</script>